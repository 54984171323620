<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button class="vd_export vg_mr_8" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()"> 刷新</el-button>
        <el-date-picker
          class="vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getDequs()"> 查询</el-button>
        <el-button v-if="isManager" type="success" size="small" class="vg_ml_8" @click="jump('mpqStatistics')">稿费稿量统计</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`dequ_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getDequs"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post, postNoCatch } from '@api/request';
import { dequAPI } from '@api/modules/dequ';
import { stffAPI } from '@api/modules/staff';
import { custAPI } from '@api/modules/cust';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getDept } from '@api/public';
import { getArrayIds } from '@assets/js/arrayUtils';
import { tableProperties } from '@/views/DevelopManagement/DequManage/dequ';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'DequList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      tableProperties: tableProperties,
      loadFlag: true,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      managerIds: []
    };
  },
  computed: {
    isManager() {
      return this.managerIds.includes(this.$cookies.get('userInfo').stff_id);
    }
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/dequ_add' || from.path === '/dequ_edit') {
        this.initData();
      }
    }
  },
  methods: {
    async initData() {
      this.managerIds = await getDept('', 'managerIds');
      this.getDequs();
    },
    // 获取公司抬头信息
    getDequs() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime?.getTime();
      searchForm.end_time = endTime?.getTime();
      getNoCatch(dequAPI.getDequs, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    // 多选获取公司抬头信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/dequ_add?perm_id=${permId}`);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message.info('已取消删除');
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      postNoCatch(dequAPI.deleteDequByIds, { dequ_id_list: getArrayIds(this.multiSelection, 'dequ_id') }).then(({ data }) => {
        this.buttonRefresh();
      });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/dequ_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.dequ_id
          })
        )
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
